<template>
  <div class="page">
    <nav-top :index="4" ref="navTop"></nav-top>
    <div class="crumbs-info">
      <div class="crumbs-search">
        <div class="crumbs">
          <div class="item">
            <router-link to="#">首页</router-link>
            <span class="right-arrows"></span>
          </div>
          <div class="item active">
            <router-link to="#">论楼宝典</router-link>
          </div>
        </div>
        <div class="search">
          <div class="search-text">
            <div class="text">
              <input type="text" placeholder="请输入关键词" v-model="keyword">
            </div>
            <div class="btn" @click="handleSearchClick">
              <img src="/static/images/fdj_ico2.png" alt="">
            </div>
          </div>
          <router-link class="report-btn" to="/report">
            <span>快速报备</span>
          </router-link>
        </div>
      </div>
    </div>
    <div class="banner-info">
      <div class="info">
        <div class="title">
          <span>论楼宝典</span>
        </div>
        <div class="desc">
          <span>A TREASURY OF KNOWLEDGE</span>
        </div>
        <div class="more">
          <img src="/static/images/xbjt_ico.png" alt="">
        </div>
      </div>
    </div>

    <div class="programa-info">
      <div class="all-programa">
        <div class="title">
          <span>全部栏目</span>
          <span>/ All columns</span>
        </div>
      </div>
      <div class="programa-tab" v-loading="cateLoading">
        <div class="tab" @click="handleTabClick(index)" :class="{active:cate.index === index}"
             v-for="(item,index) in cate.list" :key="item.id">
          <span>{{ item.name }}</span>
        </div>
      </div>
      <div class="valuable-list" v-loading="loading">
        <div class="list" v-for="(item) in list[cate.index].list" :key="item.id">
          <div class="pic">
            <img :src="item.image" alt="">
            <span>{{ item['create_date'] }}</span>
          </div>
          <div class="item">
            <div class="title">
              <span>{{ item.title }}</span>
            </div>
            <div class="file-list">
              <div class="file" v-for="file in item.file_list" :key="file.id" @click="handleFileClick(file.url)">
                <img v-if="file.type === '1'" src="/static/images/word_ico.png" alt="">
                <img v-else-if="file.type === '2'" src="/static/images/excle_ico.png" alt="">
                <img v-else-if="file.type === '3'" src="/static/images/ppt_ico.png" alt="">
                <img v-else-if="file.type === '4'" src="/static/images/pdf_ico.png" alt="">
                <span v-if="file.type === '1'">{{ file.name }}.doc</span>
                <span v-else-if="file.type === '2'">{{ file.name }}.xls</span>
                <span v-else-if="file.type === '3'">{{ file.name }}.ppt</span>
                <span v-else-if="file.type === '4'">{{ file.name }}.pdf</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="paging-info">
        <div class="pagination">
          <div class="num" :class="{active:item === list[cate.index].page}" v-for="item in list[cate.index].last"
               :key="item" @click="handlePageClick(item)">
            <span>{{ item }}</span>
          </div>
        </div>
        <div class="paging">
          <div class="cut" @click="handlePrevPageClick" v-show="list[cate.index].page !== 1">
            <span class="left"></span>
          </div>
          <div class="cut" @click="handleNextPageClick" v-show="list[cate.index].page !== list[cate.index].last">
            <span class="right"></span>
          </div>
        </div>
      </div>
    </div>
    <footer-bar></footer-bar>
  </div>
</template>

<script>
import NavTop from '../../components/NavTop/NavTop'
import FooterBar from '../../components/FooterBar/FooterBar'

export default {
  name: 'valuable',
  components: {FooterBar, NavTop},
  data() {
    return {
      keyword: '',
      cate: {
        index: 0,
        list: {}
      },
      loading: true,
      cateLoading: true,
      list: [
        {
          page: 1,
          last: 1,
          list: []
        }
      ]
    }
  },
  created() {
    this.init()
  },
  methods: {
    async init() {
      await this.fetchCate()
      await this.fetchData(0)
    },
    async fetchCate() {
      try {
        this.cateLoading = true
        let res = await this.http({
          url: '/api/article/collectionIndex',
        })

        this.cate.list = res.data
      } catch (e) {
        console.log(e)
      } finally {
        this.cateLoading = false
      }

    },
    async fetchData(index) {
      try {
        this.loading = true

        let res = await this.http({
          url: '/api/article/collectionList',
          method: 'GET',
          params: {
            keyword: this.keyword,
            cate_id: this.cate.list[index].id,
            page: this.list[index].page
          }
        })

        this.list[index].list = res.data.list
        this.list[index].last = res.data.page.last
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    async handleTabClick(index) {
      this.list[index] = {
        page: 1,
        last: 1,
        list: []
      }
      await this.fetchData(index)

      this.cate.index = index
    },
    handleFileClick(url) {
      let memberId = localStorage.getItem('MEMBER_ID')

      if (!memberId){
        this.$refs['navTop'].login()
        return
      }

      window.open(url, '_blank')
    },
    async handlePageClick(page) {
      this.list[this.cate.index].page = page
      await this.fetchData(this.cate.index)
    },
    async handleNextPageClick() {
      if (this.list[this.cate.index].page === this.list[this.cate.index].last) {
        return
      }

      this.list[this.cate.index].page++
      await this.fetchData(this.cate.index)
    },
    async handlePrevPageClick() {
      if (this.list[this.cate.index].page === 1) {
        return
      }

      this.list[this.cate.index].page--
      await this.fetchData(this.cate.index)
    },
    async handleSearchClick() {
      this.list[this.cate.index].page = 1
      await this.fetchData(this.cate.index)
    }
  },

}
</script>

<style scoped lang="scss">
@import "valuable.scss";
</style>
